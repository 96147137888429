import * as PriceListApi from '../../apis/priceList.api'
import { IElectricityRateItem, IGasRateItem, IRatesFilter, PromiseAction, TableSort } from '../../shared/interfaces'

// Get electricity rates

export const GET_ELECTRICITY_RATES_TRIGGERED = 'GET_ELECTRICITY_RATES_TRIGGERED'
export const GET_ELECTRICITY_RATES_SUCCESS = 'GET_ELECTRICITY_RATES_SUCCESS'
export const GET_ELECTRICITY_RATES_FAILURE = 'GET_ELECTRICITY_RATES_FAILURE'

export function getElectricityRates(
  filter: IRatesFilter,
  sort: TableSort<IElectricityRateItem> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = PriceListApi.getElectricityRates(filter, sort, page, size)
  return {
    onRequest: GET_ELECTRICITY_RATES_TRIGGERED,
    onSuccess: GET_ELECTRICITY_RATES_SUCCESS,
    onFailure: GET_ELECTRICITY_RATES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get gas rates

export const GET_GAS_RATES_TRIGGERED = 'GET_GAS_RATES_TRIGGERED'
export const GET_GAS_RATES_SUCCESS = 'GET_GAS_RATES_SUCCESS'
export const GET_GAS_RATES_FAILURE = 'GET_GAS_RATES_FAILURE'

export function getGasRates(
  filter: IRatesFilter,
  sort: TableSort<IGasRateItem> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = PriceListApi.getGasRates(filter, sort, page, size)
  return {
    onRequest: GET_GAS_RATES_TRIGGERED,
    onSuccess: GET_GAS_RATES_SUCCESS,
    onFailure: GET_GAS_RATES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get electricity price details

export const GET_ELECTRICITY_PRICE_DETAIL_TRIGGERED = 'GET_ELECTRICITY_PRICE_DETAIL_TRIGGERED'
export const GET_ELECTRICITY_PRICE_DETAIL_SUCCESS = 'GET_ELECTRICITY_PRICE_DETAIL_SUCCESS'
export const GET_ELECTRICITY_PRICE_DETAIL_FAILURE = 'GET_ELECTRICITY_PRICE_DETAIL_FAILURE'

export function getElectricityPriceDetail(priceId: number): PromiseAction {
  const promise = PriceListApi.getElectricityPriceDetail(priceId)
  return {
    onRequest: GET_ELECTRICITY_PRICE_DETAIL_TRIGGERED,
    onSuccess: GET_ELECTRICITY_PRICE_DETAIL_SUCCESS,
    onFailure: GET_ELECTRICITY_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId }
  }
}

// Get gas price details

export const GET_GAS_PRICE_DETAIL_TRIGGERED = 'GET_GAS_PRICE_DETAIL_TRIGGERED'
export const GET_GAS_PRICE_DETAIL_SUCCESS = 'GET_GAS_PRICE_DETAIL_SUCCESS'
export const GET_GAS_PRICE_DETAIL_FAILURE = 'GET_GAS_PRICE_DETAIL_FAILURE'

export function getGasPriceDetail(priceId: number): PromiseAction {
  const promise = PriceListApi.getGasPriceDetail(priceId)
  return {
    onRequest: GET_GAS_PRICE_DETAIL_TRIGGERED,
    onSuccess: GET_GAS_PRICE_DETAIL_SUCCESS,
    onFailure: GET_GAS_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId }
  }
}

// Update electricity price rate

export const UPDATE_ELECTRICITY_PRICE_DETAIL_TRIGGERED = 'UPDATE_ELECTRICITY_PRICE_DETAIL_TRIGGERED'
export const UPDATE_ELECTRICITY_PRICE_DETAIL_SUCCESS = 'UPDATE_ELECTRICITY_PRICE_DETAIL_SUCCESS'
export const UPDATE_ELECTRICITY_PRICE_DETAIL_FAILURE = 'UPDATE_ELECTRICITY_PRICE_DETAIL_FAILURE'

export function updateElectricityPriceDetail(priceId: number, values: IElectricityRateItem): PromiseAction {
  const promise = PriceListApi.updateElectricityPriceDetail(priceId, values)
  return {
    onRequest: UPDATE_ELECTRICITY_PRICE_DETAIL_TRIGGERED,
    onSuccess: UPDATE_ELECTRICITY_PRICE_DETAIL_SUCCESS,
    onFailure: UPDATE_ELECTRICITY_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId, values }
  }
}

// Update gas price rate

export const UPDATE_GAS_PRICE_DETAIL_TRIGGERED = 'UPDATE_GAS_PRICE_DETAIL_TRIGGERED'
export const UPDATE_GAS_PRICE_DETAIL_SUCCESS = 'UPDATE_GAS_PRICE_DETAIL_SUCCESS'
export const UPDATE_GAS_PRICE_DETAIL_FAILURE = 'UPDATE_GAS_PRICE_DETAIL_FAILURE'

export function updateGasPriceDetail(priceId: number, values: IGasRateItem): PromiseAction {
  const promise = PriceListApi.updateGasPriceDetail(priceId, values)
  return {
    onRequest: UPDATE_GAS_PRICE_DETAIL_TRIGGERED,
    onSuccess: UPDATE_GAS_PRICE_DETAIL_SUCCESS,
    onFailure: UPDATE_GAS_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId, values }
  }
}
