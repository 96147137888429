class ArrayError extends Error {
  constructor(errors) {
    super(errors.join(', '))
    this.name = 'ArrayError'
    this.errors = errors // Store the array of errors
  }
}

export default function apiMiddleware({ dispatch, getState }) {
  return next => action => {
    const { promise, onRequest, onSuccess, onFailure, ...rest } = action
    if (!promise) {
      // if action dispatched is not a promise, just send it to the next processor
      return next(action)
    }

    if (typeof onRequest === 'function') {
      onRequest(dispatch, getState)
    } else {
      dispatch({ type: onRequest, ...rest })
    }

    return promise
      .then(response => {
        if ((response?.status || response?.statusCode) >= 400) {
          if (Array.isArray(response?.message)) {
            throw new ArrayError(response.message)
          }
          throw new Error(response?.message)
        }
        try {
          if (typeof onSuccess === 'function') {
            if (action.isText) {
              onSuccess(response, dispatch, getState)
            } else {
              onSuccess(response, dispatch, getState)
            }
          } else {
            dispatch({ type: onSuccess, response: response || {}, ...rest })
          }
          return response
        } catch (e) {
          e.message = `Action success error: ${e.message}`
          e.type = 'ActionError'
          throw e
        }
      })
      .catch(error => {
        if (error.type !== 'ActionError' || error.type === 'Unauthorized') {
          if (typeof onFailure === 'function') {
            onFailure(error.response, dispatch, getState, error)
          } else {
            dispatch({ type: onFailure, error: error.response, ...rest })
          }
          return error
        }
        throw error
      })
  }
}
