import {
  CreateEicPayload,
  IEic,
  IEicDetails,
  IEicFilter,
  IEicSimpleListItem,
  IMonthlyVolume,
  IPaginationWSummaryResponse,
  ISetStartDateEicPayload,
  ISetTerminationDateEicPayload,
  TableSort
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getAllEic = (filter: IEicFilter, sort: TableSort<IEic> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_start: [filter.supply_start_date_from, filter.supply_start_date_to],
      delivery_end: [filter.supply_end_date_from, filter.supply_end_date_to],
      rzm: [filter.rzm_from, filter.rzm_to],
      status: filter.status,
      rate: filter?.rate ? filter.rate.split('_')[1] : filter.rate
    },
    searchBy: ['eic', 'customer'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationWSummaryResponse<IEic>>('/eic', payload)
}

export const getAvailableEicList = (fromDate: string) => {
  return Http.get<IEicSimpleListItem[]>(`/eic/available-eic-list/${fromDate}`)
}

export const getEicDetail = (id: number | string, signal?: AbortSignal) => {
  return Http.get<IEicDetails>(`/eic/${id}`, {}, signal)
}

export const getEicMonthlyValues = (id: number | string) => {
  return Http.get<IMonthlyVolume[]>(`/monthly-volumes/eic/${id}`)
}

export const updateEicDetail = (id: number | string, payload: Partial<IEicDetails>) => {
  return Http.patch<IEicDetails>(`/eic/${id}`, payload)
}

export const createEic = (payload: CreateEicPayload) => {
  return Http.post<IEicDetails>('/eic/create', payload)
}

export const repairMzm = (id: number) => {
  return Http.post<unknown>(`/contracts/eic/${id}/repair-mzm`)
}

export const exportEics = (filter: IEicFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_start: [filter.supply_start_date_from, filter.supply_start_date_to],
      rzm: [filter.rzm_from, filter.rzm_to],
      status: filter.status,
      rate: filter.rate
    },
    searchBy: ['eic', 'customer'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/eic/xls/export/?filter=${JSON.stringify(payload)}`)
}

export const setTerminationDateEic = (eicId: number, payload: ISetTerminationDateEicPayload) => {
  return Http.patch(`/eic/termination-date/${eicId}`, payload)
}

export const setStartDateEic = (eicId: number, payload: ISetStartDateEicPayload) => {
  return Http.patch(`/eic/start-date/${eicId}`, payload)
}
