import { IContract } from './contracts.interface'

// eslint-disable-next-line no-shadow
export enum PodStatusEnum {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY',
  ONGOING_CONNECTION = 'ONGOING_CONNECTION',
  UNSUCCESSFUL_CONNECTION = 'UNSUCCESSFUL_CONNECTION',
  ACTIVE_DELIVERY = 'ACTIVE_DELIVERY',
  DISCONNECTED_DISTRIBUTION = 'DISCONNECTED_DISTRIBUTION',
  ONGOING_TERMINATION = 'ONGOING_TERMINATION',
  TERMINATED_DELIVERY = 'TERMINATED_DELIVERY',
  DRAFT = 'DRAFT'
}

export interface IPod {
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  contract_number: number
  customer: string
  delivery_end: string
  delivery_start: string
  pod: string
  rate: string
  tdd: string
  id: number
  rzm: number
  status: PodStatusEnum
}

export interface ICreatePodDraftPayload {
  Pod: string
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  rate: string
  delivery_start: Date
  delivery_end: Date
  voltage_level: string
  number_of_phases: string
  reserved_capacity: string
  unit_of_reserved_capacity?: string
  reserved_capacity_type?: string
  rzm: string
}

export interface IPodDetails {
  address_city?: string
  address_number?: string
  address_street?: string
  address_zip_code?: string
  connections: PodConnection[]
  contracts: IContract[]
  created_at?: string
  created_by: string
  delivery_end?: string
  delivery_start?: string
  pod: string
  pod_details_id: number
  id: number
  rate: string
  status: PodStatusEnum
  updated_at: string
  updated_by: string
  tdd?: TDDEnum
  dmm?: string
}

export interface CreatePodPayload {
  pod: string
  address_street: string
  address_number: string
  address_zip_code: string
  address_city: string
  rate?: string
  delivery_start: Date
}

export interface PodConnection {
  action_id?: number
  case_id: string
  caz_id: string
  customer?: string
  Pod: string
  error_status?: number
  id: number
  pds: string
  rdz: string
  reason_of_decline?: number
  service?: string
  status?: number
  subprocess?: number
}

export interface IPodSimpleListItem {
  id: number
  pod: string
  delivery_end: string
  rate: string
  status: PodStatusEnum
}

export interface IPodAction {
  id: number
  name: string
}

export interface IPodFilter {
  search?: string
  status?: PodStatusEnum
  rate?: string
  supply_start_date_from?: string
  supply_start_date_to?: string
  supply_end_date_from?: string
  supply_end_date_to?: string
  rzm_from?: number
  rzm_to?: number
  status_code?: number
}

export interface ISetTerminationDatePodPayload {
  delivery_end: string | Date
}

// eslint-disable-next-line no-shadow
export enum TDDEnum {
  DOMO1 = 'DOMO1',
  DOMO3 = 'DOMO3',
  MOSO1 = 'MOSO1',
  MOSO2 = 'MOSO2',
  MOSO3 = 'MOSO3',
  MOSO4 = 'MOSO4',
  MOSO5 = 'MOSO5',
  MOSO6 = 'MOSO6'
}
