/* eslint-disable max-params */
import {
  IAction,
  IPaginationResponse,
  TableSort,
  IActionFilter,
  EIC_ACTION,
  IActionPayload
} from '../shared/interfaces'
import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

// Get statuses
export const getActionStatuses = () => {
  return Http.get<IPaginationResponse<IAction>>('/actions/statuses')
}

// Get actions
export const getElectricityActions = (
  type: EIC_ACTION,
  filter: IActionFilter,
  sort: TableSort<IAction> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {},
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IAction>>(`/actions/actions?type=${type}`, payload)
}

// Create actions
export const createEicAction = (messageCode: string, payload: IActionPayload) => {
  return Http.post(`/actions/create/${messageCode}`, payload)
}

export const getDistributionActionDetails = (id: number) => {
  return Http.get<IAction>(`actions/${id}`)
}
