/* eslint-disable no-shadow */
import { EicReservedCapacityType, EicSmallBusinessStatus } from './eic.interface'

export enum MESSAGE_CODE {
  CHANGE_OF_SUPPLIER = '101',
  MOVE_IN = '431',
  MOVE_OUT = '411',
  CHANGE_OF_PARAMETERS = '421'
}

export enum EIC_ACTION {
  PROCESS_EIC = 1,
  CHANGE_OF_SUPPLIER,
  MOVE_IN,
  MOVE_OUT,
  CHANGE_OF_PARAMETERS,
  DELIVERY_POINT_TRANSFER,
  PDS_SERVICE,
  VERIFICATION_OR_REJECTION,
  COMPLAINTS
}

export enum ACTION_STATUS {
  INCOMPLETE_DATA = 1,
  READY_FOR_SEND,
  SENT,
  DELIVERED,
  UNPROCESSABLE_REQUEST,
  PROCESSED,
  DECLINED,
  CANCEL_DECLINED,
  CANCELED,
  ACCEPTED,
  PAIRED,
  NOT_PAIRED
}

export enum AgreeTypeDescription {
  INDIVIDUAL_AGREEMENT = 'E01', // Agreement between the customer and PDS (individual agreement)
  COMBINED_AGREEMENT = 'E02', // Agreement between the supplier and PDS (combined agreement)
  PRODUCER_AGREEMENT = 'E03', // Agreement between the producer and PDS
  PROCESSING_TYPE = 'SOT' // Processing type for projects (used with 'SO')
}

export enum ProcessType {
  UN102 = 'UN102',
  UN104 = 'UN104',
  UN121 = 'UN121',
  UN124 = 'UN124',
  UN137 = 'UN137',
  UN138 = 'UN138',
  UN139_1 = 'UN139_1',
  UN139_2 = 'UN139_2',
  UN139_3 = 'UN139_3',
  UN148 = 'UN148',
  UN150 = 'UN150'
}

export enum ChangeOfParametersProcessType {
  CHANGE_RESERVED_CAPACITY_KW = ProcessType.UN138,
  CHANGE_RESERVED_CAPACITY_A = ProcessType.UN148,
  CHANGE_TARIFF = ProcessType.UN139_1,
  CHANGE_RATE_AND_TARIFF = ProcessType.UN139_2,
  CHANGE_RATE = ProcessType.UN139_3,
  CHANGE_CUSTOMER_DATA = ProcessType.UN137
}

export enum MoveInProcessType {
  DEFAULT = ProcessType.UN121,
  SHORT_TIME = ProcessType.UN124
}

export enum MoveOutProcessType {
  DEFAULT = ProcessType.UN102,
  SHORT_TIME = ProcessType.UN104
}

// Message 101
export interface ChangeOfSupplierAction {
  eic: string
  agreeTypeDescription: AgreeTypeDescription
}

// Message 422
// UN137
export interface ChangeParametersActionUn137 {
  eic: string
  processType: ProcessType.UN137
  pdsCustomerName?: string
  pdsCustomerStreet?: string
  pdsCustomerStreetNumber?: string
  pdsCustomerCity?: string
  pdsCustomerCountry?: string
  pdsCustomerZipCode?: string
}

// UN138
export interface ChangeParametersActionUn138 {
  eic: string
  processType: ProcessType.UN138
  reservedCapacityType: EicReservedCapacityType
  reservedCapacity: number
}

// UN139
export interface ChangeParametersActionUn139 {
  eic: string
  processType: ProcessType.UN139_1 | ProcessType.UN139_2 | ProcessType.UN139_3
  tariffCategory: string
  pdsContractType?: AgreeTypeDescription
  rate?: string
}

// UN148
export interface ChangeParametersActionUn148 {
  eic: string
  processType: ProcessType.UN148
  reservedCapacity: number
}

// UN150
export interface ChangeParametersActionUn150 {
  eic: string
  processType: ProcessType.UN150
  smallBusiness: EicSmallBusinessStatus
}

// Message 431
// UN121 / UN124
export interface MoveInAction {
  eic: string
  processType: MoveInProcessType
}

// Message 411
// UN102 / UN104
export interface MoveOutAction {
  eic: string
  processType: MoveOutProcessType
}

// Combined Action Payload Type
export type ChangeParametersAction =
  | ChangeParametersActionUn137
  | ChangeParametersActionUn138
  | ChangeParametersActionUn139
  | ChangeParametersActionUn148
  | ChangeParametersActionUn150

export type IActionPayload = ChangeOfSupplierAction | ChangeParametersAction | MoveInAction | MoveOutAction
