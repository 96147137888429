import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { PodStatusEnum } from 'src/shared/interfaces'

interface EicStatusProps {
  value?: PodStatusEnum
}

const PodStatus = (props: EicStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color

  switch (props.value) {
    case PodStatusEnum.NEW:
      color = '#e6e6e6'
      break
    case PodStatusEnum.PROCESSING:
      color = '#f1e0a7'
      break
    case PodStatusEnum.WAITING_FOR_DELIVERY:
      color = '#f3d163'
      break
    case PodStatusEnum.ONGOING_CONNECTION:
      color = '#ffa300'
      break
    case PodStatusEnum.UNSUCCESSFUL_CONNECTION:
      color = '#ff3b30'
      break
    // case PodStatusEnum.UNREALIZED_DELIVERY:
    //   color = '#ff3b30'
    //   break
    case PodStatusEnum.ACTIVE_DELIVERY:
      color = '#8abd25'
      break
    case PodStatusEnum.DISCONNECTED_DISTRIBUTION:
      color = '#ff3b30'
      break
    case PodStatusEnum.ONGOING_TERMINATION:
      color = '#ff3b30'
      break
    case PodStatusEnum.TERMINATED_DELIVERY:
      color = '#424242'
      break
    case PodStatusEnum.DRAFT:
      color = '#ddd'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`POD_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

PodStatus.displayName = 'PodStatus'
export default PodStatus
