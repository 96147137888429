import React, { MouseEvent, useEffect } from 'react'
import { Table as MuiTable, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Checkbox, Container } from '@otion-core/sandy'
import { useNavigate } from 'react-router-dom'

import { IPagination, TableSort, IColumn } from '../../shared/interfaces'
import TableHead from './TableHead'
import * as S from './styles'

export type SortDirection = 'asc' | 'desc'

type Row = {
  id: number
  [key: string]: string | number | undefined
}

interface EnhancedTable {
  /* Array of table columns */
  columns: IColumn[]
  /* Array of data objects */
  data: Row[]
  /* URL for item details click */
  detailUrlPrefix?: string
  /* Use this row field to open details (defaults to "id") */
  navigateByField?: string
  /* React row map rendering key (defaults to "id") */
  mapRenderingKey?: string
  /* Pagination object from BE */
  pagination?: IPagination
  /* Sort data handler */
  onChangeSort?: (sort: TableSort<any>) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  /* On select rows handler */
  onChangeSelected?: (selected: string[]) => void
  /* Initial sort order value */
  initialSort?: TableSort<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

const isEmpty = (value: any) => {
  return value === '' || value === undefined || value === null
}

const EnhancedTable = (props: EnhancedTable) => {
  const navigate = useNavigate()

  const { columns, data, detailUrlPrefix, onChangeSort, initialSort, pagination } = props
  const [order, setOrder] = React.useState<SortDirection>(initialSort?.direction === 'DESC' ? 'desc' : 'asc')
  const [orderBy, setOrderBy] = React.useState<string>((initialSort?.column as string) || '')
  const [selected, setSelected] = React.useState<string[]>([])

  const handleSelect = (value: string[]) => {
    setSelected(value)
    props.onChangeSelected?.(value)
  }

  useEffect(() => {
    handleSelect([])
  }, [pagination])

  const handleRequestSort = (event: React.MouseEvent<unknown>, column: string) => {
    const isAsc = orderBy === column && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(column)
    if (onChangeSort) onChangeSort({ column, direction: isAsc ? 'DESC' : 'ASC' })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map(row => row.id?.toString())
      handleSelect(newSelected)
      return
    }
    handleSelect([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    handleSelect(newSelected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const currentPage = pagination?.currentPage || 1
  const itemsPerPage = pagination?.itemsPerPage || 10
  const emptyRows = Math.min(10, Math.max(itemsPerPage, data.length)) - data.length

  return (
    <Container fullWidth bottom='medium'>
      <S.TableContainer>
        <MuiTable size='medium'>
          <TableHead
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {data.map((row, index: number) => {
              const navigationParam = row[props.navigateByField || 'id']?.toString()
              const renderingKey = row[props.mapRenderingKey || 'id']?.toString()
              const itemId = row.id?.toString()
              const isItemSelected = isSelected(itemId)

              return (
                <S.TableRow
                  hover
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={renderingKey}
                  selected={isItemSelected}
                  onClick={
                    detailUrlPrefix && navigationParam
                      ? () => navigate(`${detailUrlPrefix}/${encodeURIComponent(navigationParam)}`)
                      : undefined
                  }
                >
                  <S.TableCell padding='checkbox' onClick={event => handleClick(event, itemId)}>
                    <Container flex alignItems='center' style={{ minHeight: 25 }}>
                      {isItemSelected ? (
                        <Checkbox value={isItemSelected} />
                      ) : (
                        <React.Fragment>
                          <span className='id-counter'>{(currentPage - 1) * itemsPerPage + index + 1}</span>
                          <Checkbox value={isItemSelected} className='ghost-checkbox' />
                        </React.Fragment>
                      )}
                    </Container>
                  </S.TableCell>

                  {columns
                    .filter(column => !column.hidden)
                    .map((column, columnIndex) => {
                      const columnId = column.id
                      const formattedValue = column.format ? column.format(row[columnId], row) : row[columnId]
                      const onCellHover = (event: MouseEvent) => {
                        const domElement = event.target as HTMLTableCellElement
                        if (domElement.offsetWidth < domElement.scrollWidth) {
                          domElement.setAttribute('title', domElement.innerText)
                        }
                      }

                      return (
                        <S.TableCell
                          key={columnIndex}
                          style={{ maxWidth: `${column.width ? column.width + 'px' : 'unset'}` }}
                          onMouseEnter={onCellHover}
                        >
                          {isEmpty(formattedValue) ? '-' : formattedValue}
                        </S.TableCell>
                      )
                    })}
                  <TableCell style={{ width: 'auto' }} />
                </S.TableRow>
              )
            })}
            {data?.length && emptyRows > 0 ? (
              <TableRow style={{ height: 42 * emptyRows }}>
                <TableCell colSpan={columns.length + 2} />
              </TableRow>
            ) : null}
          </TableBody>
        </MuiTable>
      </S.TableContainer>
    </Container>
  )
}

EnhancedTable.displayName = 'EnhancedTable'

export default EnhancedTable
