import { IPaginationResponse, IElectricityRateItem, TableSort, IRatesFilter, IGasRateItem } from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getElectricityRates = (
  filter: IRatesFilter,
  sort: TableSort<IElectricityRateItem> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter,
    searchBy: ['tariff'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IElectricityRateItem>>('/rates-electricity', payload)
}
export const getGasRates = (
  filter: IRatesFilter,
  sort: TableSort<IGasRateItem> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter,
    searchBy: ['rate'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IGasRateItem>>('/rates-gas', payload)
}

export const getElectricityPriceDetail = (id: number) => {
  return Http.get<IElectricityRateItem>(`/rates-electricity/${id}`)
}

export const getGasPriceDetail = (id: number) => {
  return Http.get<IGasRateItem>(`/rates-gas/${id}`)
}

export const updateElectricityPriceDetail = (id: number, payload: IElectricityRateItem) => {
  return Http.patch<IElectricityRateItem>(`/rates-electricity/${id}`, payload)
}

export const updateGasPriceDetail = (id: number, payload: IGasRateItem) => {
  return Http.patch<IElectricityRateItem>(`/rates-gas/${id}`, payload)
}
