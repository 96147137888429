import {
  IContract,
  IContractDeliveryPointsPayload,
  IContractDetails,
  IContractDraftDeliveryPointsPayload,
  IContractsFilter,
  IContractStatus,
  ICreateContractAmendmentPayload,
  ICreateContractDraftPayload,
  ICreateContractPayload,
  IInvoiceGroup,
  IPaginationResponse,
  IPostInvoiceGroupsPayload,
  ISetContractNumberResponse,
  ISetTerminationDateContractPayload,
  IUpdateTraderPayload,
  IUploadContractFile,
  IUploadContractResponse,
  TableSort
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getAllContracts = (
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      ['contract.commodity_type' as any]: filter.commodity_type,
      status: filter.status,
      unprocessed_amendments: filter.unprocessed_amendments
    },
    searchBy: ['customer', 'contract_number', 'company_id'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IContract>>('/contracts', payload)
}

export const getContractsStatuses = () => {
  return Http.get<IContractStatus[]>(`/contracts/status`)
}

export const getContractDetail = (id: number | string, amendmentId?: number) => {
  return Http.get<IContractDetails>(`/contracts/${encodeURIComponent(id)}`, { amendmentId })
}

export const updateContractDetail = (id: number, payload: Partial<IContractDetails>) => {
  return Http.patch<IContractDetails>(`/contracts/${id}`, payload)
}

export const getContractInvoiceGroups = (id: number) => {
  return Http.get<IInvoiceGroup[]>(`/contracts/${id}/invoice-groups`)
}

export const postContractInvoiceGroups = (id: number, payload: IPostInvoiceGroupsPayload) => {
  return Http.post<{ totalUpdated: number }>(`/contracts/${id}/invoice-groups`, payload)
}

export const postContractDeliveryPoints = (id: number, payload: IContractDeliveryPointsPayload) => {
  return Http.post<unknown>(`/contracts/${id}/delivery-points`, payload)
}

export const processContract = (id: number) => {
  return Http.post<never>(`/contracts/${id}/process-contract`)
}

export const unprocessContract = (id: number) => {
  return Http.post<never>(`/contracts/${id}/unprocess-contract`)
}

export const createContract = (payload: ICreateContractPayload) => {
  return Http.post<IContractDetails>('/contracts/create', payload)
}

export const createContractAmendment = (id: number, payload: ICreateContractAmendmentPayload) => {
  return Http.post<IContractDetails>(`/contracts/${id}/create-amendment`, payload)
}

export const deleteContractAmendment = (contractId: number) => {
  return Http.delete<IContractDetails>(`/contracts/${contractId}/delete-amendment`)
}

export const setTerminationDateContract = (contractId: number, payload: ISetTerminationDateContractPayload) => {
  return Http.patch(`/contracts/termination-date/${contractId}`, payload)
}

export const setTraderId = (contractId: number, payload: IUpdateTraderPayload) => {
  return Http.patch(`/contracts/trader/${contractId}`, payload)
}

export const importContracts = (payload: IUploadContractFile, isDraft = false) => {
  const formData = new FormData()
  if (payload.file) formData.append('file', payload.file, payload.file.name)
  const headers = { 'Content-Type': 'multipart/form-data' }
  const url = isDraft ? '/contracts/draft/import' : '/contracts/import'
  return Http.post<IUploadContractResponse>(url, formData, undefined, { headers })
}

export const getContractNumber = () => {
  return Http.get<ISetContractNumberResponse>(`/contracts/suggest-contract-number`)
}

export const getDraftsContracts = (
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      status: filter.status,
      unprocessed_amendments: filter.unprocessed_amendments
    },
    searchBy: ['customer', 'contract_number', 'company_id'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IContract>>('/contracts/drafts', payload)
}

export const createDraftContract = (payload: ICreateContractDraftPayload) => {
  return Http.post<IContractDetails>('/contracts/create/draft', payload)
}

export const setApproveContractDraft = (contractId: number) => {
  return Http.patch<IContractDetails>(`/contracts/approve-draft/${contractId}`)
}

export const declineContractDraft = (contractId: number) => {
  return Http.delete<unknown>(`/contracts/decline-draft/${contractId}`)
}

export const generateDocContract = (contractNumber: string) => {
  return Http.getFile<unknown>(`/contracts/docx/generate/${contractNumber}`)
}

export const postContractDraftDeliveryPoints = (id: number, payload: IContractDraftDeliveryPointsPayload) => {
  return Http.post<unknown>(`/contracts/draft/${id}/delivery-points`, payload)
}
