import {
  CreatePodPayload,
  IPod,
  IPodAction,
  IPodDetails,
  IPodFilter,
  IPodSimpleListItem,
  IMonthlyVolume,
  IPaginationWSummaryResponse,
  ISetTerminationDatePodPayload,
  TableSort
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getAllPod = (filter: IPodFilter, sort: TableSort<IPod> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_start: [filter.supply_start_date_from, filter.supply_start_date_to],
      delivery_end: [filter.supply_end_date_from, filter.supply_end_date_to],
      rzm: [filter.rzm_from, filter.rzm_to],
      status: filter.status,
      rate: filter?.rate
    },
    searchBy: ['pod', 'customer'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationWSummaryResponse<IPod>>('/pod', payload)
}

export const getPodActions = () => {
  return Http.get<IPodAction[]>(`/pod/actions`)
}

export const getAvailablePodList = (fromDate: string) => {
  return Http.get<IPodSimpleListItem[]>(`/pod/available-pod-list/${fromDate}`)
}

export const getPodDetail = (id: number | string, signal?: AbortSignal) => {
  return Http.get<IPodDetails>(`/pod/${id}`, {}, signal)
}

export const getPodMonthlyValues = (id: number | string) => {
  return Http.get<IMonthlyVolume[]>(`/monthly-volumes/pod/${id}`)
}

export const updatePodDetail = (id: number | string, payload: Partial<IPodDetails>) => {
  return Http.patch<IPodDetails>(`/pod/${id}`, payload)
}

export const createPod = (payload: CreatePodPayload) => {
  return Http.post<IPodDetails>('/pod/create', payload)
}

export const repairMzm = (id: number) => {
  return Http.post<unknown>(`/contracts/pod/${id}/repair-mzm`)
}

export const exportPods = (filter: IPodFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_start: [filter.supply_start_date_from, filter.supply_start_date_to],
      rzm: [filter.rzm_from, filter.rzm_to],
      status: filter.status,
      rate: filter.rate
    },
    searchBy: ['pod', 'customer'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/pod/xls/export/?filter=${JSON.stringify(payload)}`)
}

export const setTerminationDatePod = (podId: number, payload: ISetTerminationDatePodPayload) => {
  return Http.patch(`/pod/termination-date/${podId}`, payload)
}
